import React, { useMemo, useState, useEffect } from 'react'
import View360, { EquirectProjection } from "@egjs/react-view360";


//Style
import './style.css'
import "@egjs/react-view360/css/view360.min.css";

//Imagens
import bosqueRecantoGrau from './img/bosque.jpg'
import loteamentoParaguacuGrau from './img/paraguacu.jpg'

function Imagem360({empreendimento}){   

    const[imagem, setImagem] = useState(null)

    const imagem360Graus = (empe) => {
        if(empe === 'bosque'){
            setImagem(bosqueRecantoGrau)
        }else{
            setImagem(loteamentoParaguacuGrau)
        }            
    }    
     

    useEffect(() => {
        imagem360Graus(empreendimento);        
    }, [empreendimento]);

    
    const projection = useMemo(() => {
        if(!imagem){
            return null
        }
        return new EquirectProjection({
            src: imagem
        })         
    }, [imagem])

    if(!projection){
        return null
    }

    
    return(
        <View360 
            className='graus-Img'
            initialPitch={90}
            projection={projection} 
        />
    )    
};

export default Imagem360;