import $ from 'jquery'
import {useState, useEffect} from 'react'

//Styles
import './style.css'

//Componente
import Imagem360 from '../Imagem360';

//Imagens
import btnLeftImg from './img/btnLeft.png'
import btnRightImg from './img/btnRight.png'

//Empreendimento - Bosque Recanto
import bosqueRecanto1 from './img/bosqueRecanto/bosque1.jpg'
import bosqueRecanto2 from './img/bosqueRecanto/bosque2.jpg'
import bosqueRecanto3 from './img/bosqueRecanto/bosque3.jpg'
import bosqueRecanto4 from './img/bosqueRecanto/bosque4.jpg'
import bosqueRecanto5 from './img/bosqueRecanto/bosque5.jpg'
import bosqueRecanto6 from './img/bosqueRecanto/bosque6.jpg'
import bosqueRecanto7 from './img/bosqueRecanto/bosque7.jpg'
import bosqueRecanto8 from './img/bosqueRecanto/bosque8.jpg'

//Empreendimento - Loteamento Paraguaçu
import loteamentoParaguacu1 from './img/loteamentoParaguacu/DJI_0004.jpg'
import loteamentoParaguacu2 from './img/loteamentoParaguacu/DJI_0006_stitch.jpg'
import loteamentoParaguacu3 from './img/loteamentoParaguacu/DJI_0025.jpg'
import loteamentoParaguacu4 from './img/loteamentoParaguacu/paraguacu2.JPG'
import loteamentoParaguacu5 from './img/loteamentoParaguacu/paraguacu1.JPG'
import loteamentoParaguacu6 from './img/loteamentoParaguacu/DJI_0534.jpg'
import loteamentoParaguacu7 from './img/loteamentoParaguacu/paraguacu3.JPG'
import loteamentoParaguacu8 from './img/loteamentoParaguacu/paraguacu4.JPG'


//Videos
import movieEmpr1 from './movie/videoTeste.mp4'

function Carrossel({ empreendimento }){ 

    //Alteração do layout do carrossel de acordo com o tamanho da tela
    const[widowsWidth, setwidowsWidth] = useState(window.innerWidth)
    const removePage = () => {
        $('.empreend-off').addClass('is-hidden')
        $('.empreend-on').removeClass('is-hidden')
    }
    const incluiPage = () => {
        $('.empreend').each(function(){
            let indexAtivo  = $('.empreend.empreend-on')       
            let indexIgnored = indexAtivo.index()
            let indexIgnoredPrev = indexIgnored -1
            let indexIgnoredNext= indexIgnored +1

            let pointPrev = $('empreend-on').eq(indexIgnoredPrev)
            let pointNext = $('empreend-on').eq(indexIgnoredNext)

            let selectPrev, selectNext

            if($(this).hasClass('empreend-on')){
                selectPrev = pointPrev.prevAll().not(pointPrev.prev())
                selectNext = pointNext.nextAll().not(pointNext.next())
                selectNext.addClass('is-hidden')
                selectPrev.addClass('is-hidden')
            }
        })
    }

    //---------------------------------------------------------------------

    const titleEmpe = (empe) =>{
        if(empe === "bosque"){
            return 'Bosque Recanto'
        }else{
            return 'Novo Paraguaçu'
        }
    }
    const[empeTitulo, setEmpeTitulo] = useState(titleEmpe(empreendimento))

    //Strings
    let listEmprens, empreAtivo

    //Numbers
    let indexMax =  0, index = 0

    
    const [img1, setImg1] = useState(null)
    const [img2, setImg2] = useState(null)
    const [img3, setImg3] = useState(null)
    const [img4, setImg4] = useState(null)

    const [img5, setImg5] = useState(null)
    const [img6, setImg6] = useState(null)
    const [img7, setImg7] = useState(null)
    const [img8, setImg8] = useState(null)  
    
    const imgCarrossel = (empe) => {
        setEmpeTitulo(titleEmpe(empe))
        if(empe === "bosque"){
            setImg1(bosqueRecanto1)
            setImg2(bosqueRecanto2)
            setImg3(bosqueRecanto3)
            setImg4(bosqueRecanto4)
            setImg5(bosqueRecanto5)
            setImg6(bosqueRecanto6)
            setImg7(bosqueRecanto7)
            setImg8(bosqueRecanto8)
        }else{
            setImg1(loteamentoParaguacu1)
            setImg2(loteamentoParaguacu2)
            setImg3(loteamentoParaguacu3)
            setImg4(loteamentoParaguacu4)
            setImg5(loteamentoParaguacu5)
            setImg6(loteamentoParaguacu6)
            setImg7(loteamentoParaguacu7)
            setImg8(loteamentoParaguacu8)
        }
    }


    useEffect(() => {        
        imgCarrossel(empreendimento);        
    }, [empreendimento]); 


    //--------------------------------------------------------------

    const  indexReturn = () => {
        listEmprens = $('.empreend')
        empreAtivo  = $('.empreend.empreend-on')       

        indexMax =  listEmprens.length - 1
        index = empreAtivo.index()         
    }

    const habilitaDesabilita = (index, indexMax, tipo) => { 
        let indexShow = 0
        
        if(index === indexMax && tipo === 'P' || tipo === 'P' ){
            index--        
        }else if(tipo === 'N'){
            index++
        }

        if(index === indexMax){
            indexShow = index-2
            for(indexShow; indexShow >= 0; indexShow--){
                listEmprens.eq(indexShow).addClass('is-hidden')                
            }

            listEmprens.eq(index-2).removeClass('is-hidden')

        }else if(index === 0){
            indexShow = index+2
            for(indexShow; indexShow <= indexMax; indexShow++){
                listEmprens.eq(indexShow).addClass('is-hidden') 
            }
            listEmprens.eq(index+2).removeClass('is-hidden')

        }else{
            indexShow = index+2
            for(indexShow; indexShow <= indexMax; indexShow++){
                listEmprens.eq(indexShow).addClass('is-hidden')  
            }

            indexShow = index-2
            for(indexShow; indexShow >= 0; indexShow--){
                listEmprens.eq(indexShow).addClass('is-hidden')
            }     
            listEmprens.eq(index-1).removeClass('is-hidden')
            listEmprens.eq(index+1).removeClass('is-hidden') 
        }          
    }

   
    const Prev = (e) => {  
        indexReturn()  
        if(index > 0){        
            //Ativo
            listEmprens.eq(index).removeClass('empreend-on') 
            listEmprens.eq(index).addClass('empreend-off')                  
            listEmprens.eq(index-1).removeClass('empreend-off')  
            listEmprens.eq(index-1).addClass('empreend-on') 

            if(widowsWidth < 1200){
                removePage()
            }else{
                incluiPage()
                habilitaDesabilita(index, indexMax, 'P')
            }
        } 
    }   
       
    const Next = (e) => {
        indexReturn()  
        if(index < indexMax){  
            //Ativo
            listEmprens.eq(index).removeClass('empreend-on') 
            listEmprens.eq(index).addClass('empreend-off') 
            listEmprens.eq(index+1).removeClass('empreend-off')  
            listEmprens.eq(index+1).addClass('empreend-on')

            if(widowsWidth < 1200){
                removePage()
            }else{
                incluiPage()
                habilitaDesabilita(index, indexMax, 'N') 
            }            
        } 
    }

    
    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setwidowsWidth(newWidth);
    
            // Defina listEmprens antes de usá-lo
            const listEmprens = $('.empreend');
            indexReturn();
    
            if (newWidth < 1200) {
                removePage();
            } else {
                
                listEmprens.removeClass('is-hidden');

                incluiPage();
    
                // Atualiza as imagens visíveis automaticamente ao redimensionar a janela
                habilitaDesabilita(index, indexMax, 'N');
            }
        };
    
        // Adicione o listener de redimensionamento
        window.addEventListener('resize', handleResize);
    
        // Executa o handleResize uma vez ao montar o componente para garantir que o layout esteja correto
        handleResize();
    
        // Limpeza ao desmontar o componente
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    
    
    

    const openModal = (img) => {
        const modal = $('<div>').addClass('modal');
        const modalContent = $('<div>').addClass('modal-content');
        const closeModal = $('<span>').addClass('close').text('×');
    
        closeModal.on('click', () => {
          modal.remove();
        });
    
        modalContent.append(closeModal);
        modalContent.append($('<img>').attr('src', img.src));
    
        modal.append(modalContent);
        $('body').append(modal);
    
        modal.on('click', (e) => {
          if ($(e.target).hasClass('modal')) {
            modal.remove();
          }
        });
    }

    return(
        <>
            <div className='content-empreend-list'>
                <div className='btnRight' onClick={Prev}>
                    <img src={btnRightImg} />
                </div>   
                
                <div className='list-empreend'>
                    <ul className='list'>
                        <li id='emp-0' className='empreend empreend-off is-hidden'>                        
                            <div className='btn-modal-empreend'>
                                <img src={img1} onClick={(e) => openModal(e.target)}/>
                                <p>{empeTitulo}</p>
                            </div>
                        </li>

                        <li id='emp-1' className='empreend empreend-off'>                        
                            <div className='btn-modal-empreend'>
                                <img src={img2} onClick={(e) => openModal(e.target)}/>
                                <p>{empeTitulo}</p>
                            </div>
                        </li>

                        <li id='emp-2' className='empreend empreend-on'>                        
                            <div className='btn-modal-empreend'>
                                <img src={img3} onClick={(e) => openModal(e.target)}/>
                                <p>{empeTitulo}</p>
                            </div>
                        </li>

                        <li id='emp-3' className='empreend empreend-off '>                        
                            <div className='btn-modal-empreend'>
                                <img src={img4} onClick={(e) => openModal(e.target)}/>
                                <p>{empeTitulo}</p>
                            </div>
                        </li>

                        <li id='emp-4' className='empreend empreend-off is-hidden'>                        
                            <div className='btn-modal-empreend'>
                                <img src={img5} onClick={(e) => openModal(e.target)}/>
                                <p>{empeTitulo}</p>
                            </div>
                        </li>

                        <li id='emp-5' className='empreend empreend-off is-hidden'>                        
                            <div className='btn-modal-empreend'>
                                <img src={img6} onClick={(e) => openModal(e.target)}/>
                                <p>{empeTitulo}</p>
                            </div>
                        </li>

                        <li id='emp-6' className='empreend empreend-off is-hidden'>                        
                            <div className='btn-modal-empreend'>
                                <img src={img7} onClick={(e) => openModal(e.target)}/>
                                <p>{empeTitulo}</p>
                            </div>
                        </li>

                        <li id='emp-7' className='empreend empreend-off is-hidden'>                        
                            <div className='btn-modal-empreend'>
                                <img src={img8} onClick={(e) => openModal(e.target)}/>
                                <p>{empeTitulo}</p>
                            </div>
                        </li>
                    </ul>                    
                </div>

                <div className='btnLeft' onClick={Next}>
                    <img src={btnLeftImg} />
                </div>                
            </div>

            <div className='content-info-empreendimentos'>
                <div className='header-empreendimento'>
                    <h4></h4>
                    <h5></h5>                    
                </div>
            </div>
       </> 
    )    

}
 

export default Carrossel;