import React, {useState, useEffect} from 'react'

//Styles
import './style.css'

//Imagens
import btnWhatsappImg from './img/btnWhatsapp.png'
import btnInstagramImg from './img/btnInstagram.png'

function Footer(){ 
    
    return(
        <>
           <footer>
                <div className='content-infos'>
                    <div className='infos'>
                    <div className='infos-contato'>
                        <h3>Contato</h3>
                        <p>Telefone | (71) 9 9246-6940</p>
                        <p>E-mail | uei.imobiliaria@gmail.com</p>
                        <div>
                        <a href="https://www.instagram.com/uei.ilha?igsh=MTVwbmFzejFweWJnbQ%3D%3D" target='__blank'>
                            <img src={btnInstagramImg} />
                        </a>
                        <a href="https://wa.me/5571992466940?text=Ol%C3%A1%20UEI%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20um%20dos%20empreendimentos." target='__blank'>
                            <img src={btnWhatsappImg} />
                        </a>
                        </div>
                    </div>
                    <div className='infos-empresa'>
                        <h3>Informações</h3>
                        <p>Endereço | Mar Grande, Vera Cruz - BA</p>
                        <p>Horário de Funcionamento | Segunda a Sexta 08:06h as 17:00h</p>
                        <p>CEP | 44470-000</p>
                        <p>CNPJ | 39.240.382/0001-66</p>
                    </div>
                    </div>
                    <div className='google-map'>
                    <h3>Localização</h3>
                    <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d687.2655961541458!2d-38.63390686159886!3d-12.98711288095711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7160169d322a2af%3A0x7a0789908a52604f!2sUniverso%20Empreendimento%20Imobili%C3%A1rio!5e0!3m2!1spt-BR!2sbr!4v1725418811274!5m2!1spt-BR!2sbr" loading="lazy" content="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>      
            </footer>
        </>
    )
} 

export default Footer;