import {useState, useEffect} from 'react'
import emailjs from '@emailjs/browser'
import $ from 'jquery'


//Styles
import './style/App.css';
import './style/colors.css';
import './style/wideScreen.css';

//Components
import Header from './components/Header';
import Footer from './components/Footer';
import ButtonTop from './components/ButtonTop';
import Carrossel from './components/Carrossel';
import Imagem360 from './components/Imagem360';
import TextInfoEmpreendimento from './components/TextInfoEmpreendimento';

//Imagens
import bosque from './img/bosque.png'
import paraguacu from './img/paraguacu.png'
import integridade from './img/integridade.png'
import objetivo from './img/objetivo.png'
import atendimento from './img/atendimento.png'

//Video
import videoBosqueRecanto from './videos/BosqueRecanto.mp4'
import loteamentoParaguacu from './videos/LoteamentoParaguacu.mp4'


//----------------------------------------------

function App() {

  // Constantes do UseState
  const [nome, setNome] = useState('')
  const [sobrenome, setSobrenome] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')
  const [mensagem, setMensagem] = useState('')
  const [error, setError] = useState('')

  const [empreendimento, setEmpreendimento] = useState('bosque')
  const [videoEmpreendimento, setVideoEmpreendimento] = useState(null)
  

  
  const sendEmail = (e) => {
    e.preventDefault();    
      if(nome === '' || sobrenome === '' || telefone === '' || email === ''){
        alert("Preencha todos os campos");
        return;
      }
      
      const templateParams = {
        from_name: nome,
        surname: sobrenome,
        email: email,
        phone: telefone
      }

      emailjs.send("service_f3ejlxq", "template_k060plr", templateParams, "KBt3o4VNO9MZwNiAi")
      .then((response)=>{
        
        setNome('')
        setSobrenome('')
        setTelefone('')
        setEmail('')

        setMensagem("Email enviado com sucesso. Entraremos em contato em breve!")
        setTimeout(() =>{
          setMensagem('')
        }, 4000)       

      }, (err) =>{
        console.log("ERRO: ", err)
        setError("Ocorreu um erro. Por favor, verifique o e-mail inserido.")
        setTimeout(() =>{
          setError('')
        }, 4000)

      })
  }

  const infoTextEmpe = (n, empe) =>{
    if(n === 1){
      return `• Localizado no Coração da ilha (Entroncamento de Mar Grande)<br/>
              • Próximo aos terminais marítimos<br/>
              • Próximos a posto de combustível e conveniência<br/>
              • Próximo aos mercados Atacadão e Atakarejo<br/>
              • Próximo a BA-001`      

    }else if(n === 2){
      if(empe === 'bosque'){
        return `• Rede elétrica<br/>
              • Rede de água<br/>
              • Rede de escoamento hídrico<br/>
              • Iluminação moderna em todo empreendimento<br/>
              • Cascalho em todas as vias<br/>
              • Meio fio em todas as vias<br/>
              • Portaria com guarita<br/>
              • Área de Lazer (Quadra poliesportiva, ciclovia, quiosque e área verde planejada)`

      }else{
        return `• Rede elétrica<br/>
              • Rede de água<br/>
              • Rede de escoamento hídrico<br/>
              • Iluminação moderna em todo empreendimento<br/>
              • Cascalho em todas as vias<br/>
              • Meio fio em todas as vias<br/>
              • Portaria com guarita`
      }

    }else if(n === 3){
      return `• Localização estratégica<br/>
              • Empreendimento 100% regularizado<br/>
              • Pavimentação até a entrada do empreendimento<br/>
              • Condições de pagamento que apenas a UEI proporciona`
    }

  }

  const selectEmpreendimento = (empe) => {
    setEmpreendimento(empe)

    setTextEmp1(infoTextEmpe(1, empe))
    setTextEmp2(infoTextEmpe(2, empe))
    setTextEmp3(infoTextEmpe(3, empe))

    if(empe === 'bosque'){
      $('#bosque').addClass('ativoEmpe')
      $('#paraguacu').removeClass('ativoEmpe')
    }else{
      $('#paraguacu').addClass('ativoEmpe')
      $('#bosque').removeClass('ativoEmpe')
    }

  }

  const [textEmp1, setTextEmp1] = useState(infoTextEmpe(1, 'bosque'))
  const [textEmp2, setTextEmp2] = useState(infoTextEmpe(2, 'bosque'))
  const [textEmp3, setTextEmp3] = useState(infoTextEmpe(3, 'bosque'))  

  const videoEmpe = (empe) => {
    if(empe === 'bosque'){
      setVideoEmpreendimento(videoBosqueRecanto)

    }else{
      setVideoEmpreendimento(loteamentoParaguacu)

    }
  }

  useEffect(() => {
    videoEmpe(empreendimento);
  }, [empreendimento]);
 

  return (
    <>
      <ButtonTop></ButtonTop>
      
      <Header></Header>      

      <section id='empreendimentos'>
        <div className='content-section'>

          <div className='header-empreendimentos'>
            <h2>Nossos Empreendimentos</h2>

            <div className='content-empe'>
              <div id='bosque' className='ativoEmpe' onClick={() => selectEmpreendimento('bosque')}>
                <img src={bosque} alt='logo empreendimento Bosque Recanto'></img> 
              </div>
              <div id='paraguacu' onClick={() => selectEmpreendimento('paraguacu')}>
                <img src={paraguacu} alt='logo empreendimento Novo Paraguaçu'></img> 
              </div>
            </div>
            
          </div>
          
          <Carrossel empreendimento={empreendimento}></Carrossel>    

          <div className='text-empreendimento'>
            <p>
              <strong>Localização Estratégica</strong><br/>
              <TextInfoEmpreendimento html={textEmp1}></TextInfoEmpreendimento>
            </p>
            <p>
              <strong>Infraestrutura</strong><br/>
              <TextInfoEmpreendimento html={textEmp2}></TextInfoEmpreendimento>
            </p>
            <p>
              <strong>Mais Benefícios</strong><br/>
              <TextInfoEmpreendimento html={textEmp3}></TextInfoEmpreendimento>
            </p>
          </div>

          <div className='content-info-empreendimentos'>
            <Imagem360 empreendimento={empreendimento}></Imagem360>            
            <video className='video-empreendimento' src={videoEmpreendimento} autoPlay muted loop></video>
          </div>          
        </div>            
      </section>



      <section id='sobre'>
        <div className='content-section'>
          <div className='cards-header'>
            Mantendo nosso compromisso com a qualidade, preservamos nossos princípios, sempre focados no principal objetivo: <i>REALIZAR SONHOS</i>
          </div>
          
          <div className='content-cards'>            
            <div className='cards'>              
              <h4>Objetivo</h4>      
              <img src={objetivo}></img>           
              <p>Nosso objetivo é transformar o sonho da casa própria em realidade. Com 5 anos de experiência em Mar Grande-BA, oferecemos empreendimentos regularizados que garantem segurança e confiança em cada compra.</p>
            </div>
            <div className='cards'>              
              <h4>Integridade e Transparência</h4>  
              <img src={integridade}></img>            
              <p>Prezamos pela integridade e transparência. Todos os nossos imóveis são regularizados, assegurando uma compra segura e sem surpresas, com clareza em cada etapa do processo.</p>
            </div>
            <div className='cards'>
              <h4>Excelência no Atendimento</h4>
              <img src={atendimento}></img>
              <p>Nossa equipe está sempre pronta para oferecer um atendimento de alta qualidade. Garantimos que nossos clientes se sintam confortáveis e realizados com suas escolhas, do início ao fim.</p>
            </div>            
          </div>

          <div className='cards-footer'>
            <h3>Transforme Seus Sonhos em Realidade <br/>Garanta Sua Unidade</h3>
          </div>

        </div>       
      </section>



      <section id='contato'>
        <div className='content-section'>
          <h2>Entre em Contato Conosco</h2>   
          <div className='content-form'>
            <form onSubmit={sendEmail}>
              <input 
                id='email'
                className='inputLabel' 
                type='email' 
                onChange={(e) => setEmail(e.target.value)} 
                value={email}  
                placeholder='E-Mail'    
                required           
              />

              <div> 
                <input 
                  id='nome'
                  className='inputLabel' 
                  type='text' 
                  onChange={(e) => setNome(e.target.value)} 
                  value={nome} 
                  placeholder='Nome'
                  required
                />

                <input 
                  id='sobrenome'
                  className='inputLabel' 
                  type='text'                 
                  onChange={(e) => setSobrenome(e.target.value)} 
                  value={sobrenome} 
                  placeholder='Sobrenome'  
                  required              
                />
              </div>            
                              
              <input    
                id='telefone'           
                className='inputLabel' 
                type='tel' 
                onChange={(e) => setTelefone(e.target.value)} 
                value={telefone} 
                placeholder='Telefone para contato'
                required
              />
                                
              <button className='btn'>Enviar</button>

              <p className='mensagem'>{mensagem}</p>
              <p className='erro'>{error}</p>
            </form>

            

          </div>  
            
        </div>  
        <hr/>     
      </section>

      <Footer></Footer>    

    </>
  );
}

export default App;
